@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tab-content{
    border: 0;
    border-radius: 8px;
    border-top-left-radius: 0;
    background: white;
  }


  .MuiSlider-root.MuiSlider-marked{
    width: 90% !important;
  }

  .MuiRadioGroup-root.MuiRadioGroup-horizontal{
    width: 92%;
    justify-content: space-around;
  }

  .App-main{
    background: #efeff6;
    /* background: #5091ce; */
    overflow-y: auto;
    max-height: 100vh;
  }

  .nav-link.active{
    color: #5091ce !important;
    border-color: #5091ce !important;
    border-bottom-color: white !important;
  }
.nav-link{
  /* font-weight: 600 !important; */
  font-size: large !important;
  color: black !important;
  border-bottom-color: #5091ce !important;
  font-weight: bolder !important;
}

   @keyframes scaleUp {
    from {
       transform: scale(0);
    }
    to {
       transform: scale(1);
    }
   }
   
   /* Scale down animation */
   @keyframes scaleDown {
    from {
       transform: scale(1);
    }
    to {
       transform: scale(0);
    }
   }
   .tab-pane.active div:first-of-type{
    animation: scaleUp 0.5s forwards;
   }

   .scaleup{
    animation: scaleUp 0.5s forwards;
   }







   .modern-card {
    /* margin-bottom: 2rem; */
    align-items: center;
    display: flex;
   }
   
   .modern-card .card {
    border: none;
    border-radius: 12px;
    
    /* box-shadow: 5px 2px 9px #5091ce !important */
   }
   .modern-card .card:hover{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important; 
   }
   .card:hover{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important; 
   }
   .card{
    box-shadow: var(--bs-box-shadow-sm) !important;
   }
   .modern-card .card-body {
    padding: 1.5rem;
   }
   
   .modern-card .card-title {
    font-size: 1.25rem; 
    margin-bottom: 0.5rem;
   }
   
   .modern-card .card-text {
    font-size: 1rem; 
    color: #333; 
   }




   .tab-pane{
    border-color: #5091ce;
    border: 1px solid #5091ce;
    border-top-color: white;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
   }

   .textprimary{
    color: #5091ce  !important;
   }

   .MuiRadioGroup-root{
    background: #5091ce !important;
   }

   .MuiRadio-label{
    color: white;
   }

   .Mui-checked label{
    color: black;
   }

   .MuiRadio-root:hover label{
    color: black;
   }

   .input-group-text{
    background: #5091ce !important;
    color: white !important;
   }

   .tooltip-arrow::before {
    border-right-color: #669ed4 !important;
}
.tooltip-inner {
    background: #669ed4 !important;
}










.blurred-card {
  position: relative;
  background: #efeff6;
  border-radius: 13px;
  background-image: linear-gradient(to right, #6dd6eb, #0c33a6);
  width: 200%;
 }
 /* .bllurred-one{
  top:-20%
 }
 .bllurred-two{
  top:-12%;
 }
 .bllurred-three{
  top:-10%;
 }
 .bllurred-four{
  top:-9%;
 } */
 .blur {
  filter: blur(2px);
 }
 .one{
  right: 13.7%;
 }
 .two{
  right: 13.7%;
 }
 .three{
  right: 41%;
  top: 37%;
 }
 .four{
  right: 13.7%;
 }
 .heartbeat {
  position: absolute;
  z-index: 2;
  animation: pulse 1s infinite !important;
  background-image: linear-gradient(to right, #6dd6eb, #0c33a6);
  color: white;
  display: grid;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 8px 10px 15px #5091ce;
  /* box-shadow: 8px 4px 15px #5091ce; */
 }
 
 @keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
 }
  50% {
    transform: scale(1.1);
    opacity: 1;
    box-shadow: 8px 20px 15px #5091ce;
 }
  100% {
    transform: scale(1);
    opacity: 1;
 }
}

.modal-header
{
  background-image: linear-gradient(to right, #02578a, #132946);
  color: white;
}
.btn-close{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath stroke='%23fff' stroke-width='1' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")  ;
  filter: invert(100%);
}


.Title{
  font-weight: 500;
    color: white;
    font-size: 46px;
    font-family: Poppins;
}
.SubTitle{
  color: white;
    font-size: 22px;
    font-family: Poppins;
}
/* .modern-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
 } */
.App-main li button{
  height: 100%;
}
input[name="contractLength"] {
  font-size: 5px; /* Adjust the font size as needed */
}
.MuiRadioGroup-variantPlain{
  margin-left: 1.5rem !important;
}


/* Medium devices (tablets, 768px and up) */
 @media (max-width: 768px) {
  .Title {
      font-size: 30px !important;
  }
  .SubTitle{
    font-size: 20px !important;
  }
  .header img{
    height: 100px !important;
  }
  .tab-img{
    height: 10rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .MuiRadioGroup-root.MuiRadioGroup-horizontal.MuiRadioGroup-variantPlain.MuiRadioGroup-colorNeutral.MuiRadioGroup-sizeMd{
    display: block;
  }
  .MuiRadioGroup-root.MuiRadioGroup-horizontal.MuiRadioGroup-variantPlain.MuiRadioGroup-colorNeutral.MuiRadioGroup-sizeMd span{
    width: 100%;
    margin: 0;
  }
  .one {
    right: 9.7%;
  }
  .labels{
    font-size: 15px !important;
  }
  .MuiRadioGroup-variantPlain{
    margin-left: .75rem !important;
  }
  .MuiSlider-markLabel.MuiSlider-markLabel {
    font-size: 12px ;
  }
  
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .Title {
      font-size: 15px !important;
  }
  .SubTitle{
    font-size: 12px !important;
  }
  .header_img{
    height: 100px !important;
  }
  .tab-img{
    height: 8rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .one {
    right: 33.7%;
} 
.two {
  right: 33.7%;
}
.four {
  right: 32.7%;
}
.labels{
  font-size: 15px !important;
}
.MuiRadioGroup-variantPlain{
  margin-left: .75rem !important;
}
.MuiSlider-markLabel.MuiSlider-markLabel {
  font-size: 10px ;
}
.card-body .input-group input{
  font-size: 10px;
  height: 25px;
}
.card-body .input-group .input-group-text{
  font-size: 10px;
  height: 25px;
}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 375px) {
  .Title {
      font-size: 12px !important;
  }
  .SubTitle{
    font-size: 10px !important;
  }
  .header_img {
    height: 100px !important;
  }
  .tab-img{
    height: 8rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .labels{
    font-size: 15px !important;
  }
  .MuiRadioGroup-variantPlain{
    margin-left: .75rem !important;
  }
  .MuiSlider-markLabel.MuiSlider-markLabel {
    font-size: 10px ;
  }
  .card-body .input-group input{
    font-size: 10px;
    height: 25px;
  }
  .card-body .input-group .input-group-text{
    font-size: 10px;
    height: 25px;
  }
}
:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-blue: #1976d2;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}
.css-14hbvrr-JoyRadioGroup-root {
  background-color:rgb(81, 145, 206, .2) !important;
}

.css-j09amc-JoyRadio-action {
  color: white !important;
  background-color: rgb(1, 91, 151, .2) !important;
  border: rgb(1, 91, 151) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
color: rgb(131 131 131 / 60%) !important;
text-transform: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected  {
  background-color:rgb(81, 145, 206, .2) !important; 
  color: #015b97 !important;
  font-weight: 700 !important;
}

.grid {
  display: grid;
  margin: 0 auto;
  max-width: 60em;
  padding: 0;
 
  @media (min-width: 42em) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  
  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: .5em;
  top: .9em;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) { 
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: 
      background 0.2s ease-out,
      border-color 0.2s ease-out;
    width: var(--radio-size); 

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: '';
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: 
        rotate(45deg)
        translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
  
  .card:hover .radio {
    border-color: var(--color-dark-gray);
    
    &:checked {
      border-color: var(--color-blue);
    }
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  border-color: var(--color-blue);
}

.radio:focus ~ .plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled ~ .plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled ~ .plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled ~ .plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
    border-color: var(--color-gray);
  }

.plan-type {
  color: var(--color-blue);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-feature-settings: "kern" off;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-blue: #1976d2;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.grid1 {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0 auto;
  max-width: 60em;
  padding: 0;
 
  @media (min-width: 42em) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card1 {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  
  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.radio1 {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: .5em;
  top: .9em;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) { 
  .radio1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: 
      background 0.2s ease-out,
      border-color 0.2s ease-out;
    width: var(--radio-size); 

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: '';
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: 
        rotate(45deg)
        translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
  
  .card1:hover .radio1 {
    border-color: var(--color-dark-gray);
    
    &:checked {
      border-color: var(--color-blue);
    }
  }
}

.plan-details1 {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card1:hover .plan-details1 {
  border-color: var(--color-dark-gray);
}

.radio1:checked ~ .plan-details1 {
  border-color: var(--color-blue);
}

.radio1:focus ~ .plan-details1 {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio1:disabled ~ .plan-details1 {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio1:disabled ~ .plan-details1 .plan-type1 {
  color: var(--color-dark-gray);
}

.card1:hover .radio1:disabled ~ .plan-details1 {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card1:hover .radio1:disabled {
    border-color: var(--color-gray);
  }

.plan-type1 {
  color: var(--color-blue);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1em;
}

.slash {
  font-weight: normal;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.spade-radiogroup{
  width:70%;
  padding-left: 2.5%;
  display: flex;
  flex-wrap: nowrap;
}
.spade-radio{
  width:40%;
  display: flex;
  flex-wrap: nowrap;
  justify-content:space-evenly;
  align-items: center;
}
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.container {
    position: relative;
    height: 100vh;
    background: linear-gradient(to bottom right, #D8E1FB, #E9DCEC);
    max-width: 100vw;
    border-radius: 20px;
}

.top-div {
    height: 50%;
}

.bottom-div {
    height: 50%;
    background: white;
    border-top-left-radius: 50% 20px;
    border-top-right-radius: 50% 20px;
    overflow: hidden;
}

.centered-div {
    /*position: absolute;*/
    overflow: auto;
    top: 45%;
    left: 50%;
    /*transform: translate(-50%, -50%);*/
    height: 100%;
    /*width: 90%;*/
    background: white;
    /*border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

@media only screen and (min-width: 769px) {
    .centered-div {
        overflow-x: hidden;
    }
}

.heading {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.plan-checkbox {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}



span {
    font-size: 13px;
}
.accordion{
    min-width:-webkit-min-content;
    min-width:min-content;
}
.plans {
    flex: 1 1;
    min-width: 7.4rem;
}
.plan-checkbox {
    min-width: 7.4rem;
    /*min-width:6rem*/
}

.pricing-detail {
    min-width: 7.4rem;
}
.selectedplan ul li {
    background: lightblue;
}

.selectedplan {
    background: lightblue;
}

.selectedplan.hover-effect ul li {
    background: lightblue;
}

.selectedplan.hover-effect {
    background: lightblue;
}

.form-check-input:checked {
    background-color: green;
    border-color: green;
}

.form-check-input:focus {
    border-color: white;
    box-shadow: none;
}

.form-check-input {
    font-size: 25px;
}

.accordion-button {
    font-weight: bold;
    font-size: 13px;
    padding: 0.5rem 1rem;
}

.accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: none;
}

.hover-effect ul li {
    background: beige;
}

.hover-effect {
    background: beige;
}



.note-list {
    list-style: none;
    counter-reset: list-counter;
}

.note-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 13px;
    justify-content: flex-start;
    display: flex;
}

.note-list li::before {
    content: counter(list-counter) ". ";
    counter-increment: list-counter;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}


.tooltip-arrow::before {
    border-right-color: #669ed4 !important;
}

.tooltip-inner {
    background: #669ed4;
}





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

