@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.tab-content{
    border: 0;
    border-radius: 8px;
    border-top-left-radius: 0;
    background: white;
  }


  .MuiSlider-root.MuiSlider-marked{
    width: 90% !important;
  }

  .MuiRadioGroup-root.MuiRadioGroup-horizontal{
    width: 92%;
    justify-content: space-around;
  }

  .App-main{
    background: #efeff6;
    /* background: #5091ce; */
    overflow-y: auto;
    max-height: 100vh;
  }

  .nav-link.active{
    color: #5091ce !important;
    border-color: #5091ce !important;
    border-bottom-color: white !important;
  }
.nav-link{
  /* font-weight: 600 !important; */
  font-size: large !important;
  color: black !important;
  border-bottom-color: #5091ce !important;
  font-weight: bolder !important;
}

   @keyframes scaleUp {
    from {
       transform: scale(0);
    }
    to {
       transform: scale(1);
    }
   }
   
   /* Scale down animation */
   @keyframes scaleDown {
    from {
       transform: scale(1);
    }
    to {
       transform: scale(0);
    }
   }
   .tab-pane.active div:first-of-type{
    animation: scaleUp 0.5s forwards;
   }

   .scaleup{
    animation: scaleUp 0.5s forwards;
   }







   .modern-card {
    /* margin-bottom: 2rem; */
    align-items: center;
    display: flex;
   }
   
   .modern-card .card {
    border: none;
    border-radius: 12px;
    
    /* box-shadow: 5px 2px 9px #5091ce !important */
   }
   .modern-card .card:hover{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important; 
   }
   .card:hover{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important; 
   }
   .card{
    box-shadow: var(--bs-box-shadow-sm) !important;
   }
   .modern-card .card-body {
    padding: 1.5rem;
   }
   
   .modern-card .card-title {
    font-size: 1.25rem; 
    margin-bottom: 0.5rem;
   }
   
   .modern-card .card-text {
    font-size: 1rem; 
    color: #333; 
   }




   .tab-pane{
    border-color: #5091ce;
    border: 1px solid #5091ce;
    border-top-color: white;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
   }

   .textprimary{
    color: #5091ce  !important;
   }

   .MuiRadioGroup-root{
    background: #5091ce !important;
   }

   .MuiRadio-label{
    color: white;
   }

   .Mui-checked label{
    color: black;
   }

   .MuiRadio-root:hover label{
    color: black;
   }

   .input-group-text{
    background: #5091ce !important;
    color: white !important;
   }

   .tooltip-arrow::before {
    border-right-color: #669ed4 !important;
}
.tooltip-inner {
    background: #669ed4 !important;
}










.blurred-card {
  position: relative;
  background: #efeff6;
  border-radius: 13px;
  background-image: linear-gradient(to right, #6dd6eb, #0c33a6);
  width: 200%;
 }
 /* .bllurred-one{
  top:-20%
 }
 .bllurred-two{
  top:-12%;
 }
 .bllurred-three{
  top:-10%;
 }
 .bllurred-four{
  top:-9%;
 } */
 .blur {
  filter: blur(2px);
 }
 .one{
  right: 13.7%;
 }
 .two{
  right: 13.7%;
 }
 .three{
  right: 41%;
  top: 37%;
 }
 .four{
  right: 13.7%;
 }
 .heartbeat {
  position: absolute;
  z-index: 2;
  animation: pulse 1s infinite !important;
  background-image: linear-gradient(to right, #6dd6eb, #0c33a6);
  color: white;
  display: grid;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 8px 10px 15px #5091ce;
  /* box-shadow: 8px 4px 15px #5091ce; */
 }
 
 @keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
 }
  50% {
    transform: scale(1.1);
    opacity: 1;
    box-shadow: 8px 20px 15px #5091ce;
 }
  100% {
    transform: scale(1);
    opacity: 1;
 }
}

.modal-header
{
  background-image: linear-gradient(to right, #02578a, #132946);
  color: white;
}
.btn-close{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath stroke='%23fff' stroke-width='1' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")  ;
  filter: invert(100%);
}


.Title{
  font-weight: 500;
    color: white;
    font-size: 46px;
    font-family: Poppins;
}
.SubTitle{
  color: white;
    font-size: 22px;
    font-family: Poppins;
}
/* .modern-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
 } */
.App-main li button{
  height: 100%;
}
input[name="contractLength"] {
  font-size: 5px; /* Adjust the font size as needed */
}
.MuiRadioGroup-variantPlain{
  margin-left: 1.5rem !important;
}


/* Medium devices (tablets, 768px and up) */
 @media (max-width: 768px) {
  .Title {
      font-size: 30px !important;
  }
  .SubTitle{
    font-size: 20px !important;
  }
  .header img{
    height: 100px !important;
  }
  .tab-img{
    height: 10rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .MuiRadioGroup-root.MuiRadioGroup-horizontal.MuiRadioGroup-variantPlain.MuiRadioGroup-colorNeutral.MuiRadioGroup-sizeMd{
    display: block;
  }
  .MuiRadioGroup-root.MuiRadioGroup-horizontal.MuiRadioGroup-variantPlain.MuiRadioGroup-colorNeutral.MuiRadioGroup-sizeMd span{
    width: 100%;
    margin: 0;
  }
  .one {
    right: 9.7%;
  }
  .labels{
    font-size: 15px !important;
  }
  .MuiRadioGroup-variantPlain{
    margin-left: .75rem !important;
  }
  .MuiSlider-markLabel.MuiSlider-markLabel {
    font-size: 12px ;
  }
  
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .Title {
      font-size: 15px !important;
  }
  .SubTitle{
    font-size: 12px !important;
  }
  .header_img{
    height: 100px !important;
  }
  .tab-img{
    height: 8rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .one {
    right: 33.7%;
} 
.two {
  right: 33.7%;
}
.four {
  right: 32.7%;
}
.labels{
  font-size: 15px !important;
}
.MuiRadioGroup-variantPlain{
  margin-left: .75rem !important;
}
.MuiSlider-markLabel.MuiSlider-markLabel {
  font-size: 10px ;
}
.card-body .input-group input{
  font-size: 10px;
  height: 25px;
}
.card-body .input-group .input-group-text{
  font-size: 10px;
  height: 25px;
}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 375px) {
  .Title {
      font-size: 12px !important;
  }
  .SubTitle{
    font-size: 10px !important;
  }
  .header_img {
    height: 100px !important;
  }
  .tab-img{
    height: 8rem !important;
  }
  #justify-tab-example-tab-1,#justify-tab-example-tab-2,#justify-tab-example-tab-3,#justify-tab-example-tab-4{
    font-size: 11px !important;
  }
  .labels{
    font-size: 15px !important;
  }
  .MuiRadioGroup-variantPlain{
    margin-left: .75rem !important;
  }
  .MuiSlider-markLabel.MuiSlider-markLabel {
    font-size: 10px ;
  }
  .card-body .input-group input{
    font-size: 10px;
    height: 25px;
  }
  .card-body .input-group .input-group-text{
    font-size: 10px;
    height: 25px;
  }
}