:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-blue: #1976d2;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.grid1 {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0 auto;
  max-width: 60em;
  padding: 0;
 
  @media (min-width: 42em) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card1 {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  
  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.radio1 {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: .5em;
  top: .9em;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) { 
  .radio1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: 
      background 0.2s ease-out,
      border-color 0.2s ease-out;
    width: var(--radio-size); 

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: '';
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: 
        rotate(45deg)
        translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
  
  .card1:hover .radio1 {
    border-color: var(--color-dark-gray);
    
    &:checked {
      border-color: var(--color-blue);
    }
  }
}

.plan-details1 {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card1:hover .plan-details1 {
  border-color: var(--color-dark-gray);
}

.radio1:checked ~ .plan-details1 {
  border-color: var(--color-blue);
}

.radio1:focus ~ .plan-details1 {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio1:disabled ~ .plan-details1 {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio1:disabled ~ .plan-details1 .plan-type1 {
  color: var(--color-dark-gray);
}

.card1:hover .radio1:disabled ~ .plan-details1 {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card1:hover .radio1:disabled {
    border-color: var(--color-gray);
  }

.plan-type1 {
  color: var(--color-blue);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1em;
}

.slash {
  font-weight: normal;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.spade-radiogroup{
  width:70%;
  padding-left: 2.5%;
  display: flex;
  flex-wrap: nowrap;
}
.spade-radio{
  width:40%;
  display: flex;
  flex-wrap: nowrap;
  justify-content:space-evenly;
  align-items: center;
}