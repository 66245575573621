body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.container {
    position: relative;
    height: 100vh;
    background: linear-gradient(to bottom right, #D8E1FB, #E9DCEC);
    max-width: 100vw;
    border-radius: 20px;
}

.top-div {
    height: 50%;
}

.bottom-div {
    height: 50%;
    background: white;
    border-top-left-radius: 50% 20px;
    border-top-right-radius: 50% 20px;
    overflow: hidden;
}

.centered-div {
    /*position: absolute;*/
    overflow: auto;
    top: 45%;
    left: 50%;
    /*transform: translate(-50%, -50%);*/
    height: 100%;
    /*width: 90%;*/
    background: white;
    /*border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

@media only screen and (min-width: 769px) {
    .centered-div {
        overflow-x: hidden;
    }
}

.heading {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.plan-checkbox {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}



span {
    font-size: 13px;
}
.accordion{
    min-width:min-content;
}
.plans {
    flex: 1;
    min-width: 7.4rem;
}
.plan-checkbox {
    min-width: 7.4rem;
    /*min-width:6rem*/
}

.pricing-detail {
    min-width: 7.4rem;
}
.selectedplan ul li {
    background: lightblue;
}

.selectedplan {
    background: lightblue;
}

.selectedplan.hover-effect ul li {
    background: lightblue;
}

.selectedplan.hover-effect {
    background: lightblue;
}

.form-check-input:checked {
    background-color: green;
    border-color: green;
}

.form-check-input:focus {
    border-color: white;
    box-shadow: none;
}

.form-check-input {
    font-size: 25px;
}

.accordion-button {
    font-weight: bold;
    font-size: 13px;
    padding: 0.5rem 1rem;
}

.accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: none;
}

.hover-effect ul li {
    background: beige;
}

.hover-effect {
    background: beige;
}



.note-list {
    list-style: none;
    counter-reset: list-counter;
}

.note-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 13px;
    justify-content: flex-start;
    display: flex;
}

.note-list li::before {
    content: counter(list-counter) ". ";
    counter-increment: list-counter;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}


.tooltip-arrow::before {
    border-right-color: #669ed4 !important;
}

.tooltip-inner {
    background: #669ed4;
}




